// 首先，导入React和A-Frame
// import 'aframe';
import React from 'react';
import "https://aframe.io/releases/1.0.0/aframe.min.js"

// 定义VRScene组件
const VRScene = () => {
  return (
    <a-scene>
      {/* 立方体 */}
      <a-box position="-1 0.5 -3" rotation="0 45 0" color="#4CC3D9" />

      {/* 球体 */}
      <a-sphere position="0 1.25 -5" radius="1.25" color="#EF2D5E" />

      {/* 圆柱形 */}
      <a-cylinder position="1 0.75 -3" radius="0.5" height="1.5" color="#FFC65D" />

      {/* 平面 */}
      <a-plane position="0 0 -4" rotation="-90 0 0" width="4" height="4" color="#7BC8A4" />

      {/* 天空 */}
      <a-sky color="#ECECEC" />

      <a-text
          value="Hello, World!　こんにちは！！！ Testing" // 文字内容
          position="-1 1.5 -3" // 文字位置
          color="#FF0000" // 文字颜色
        ></a-text>

      {/* <a-entity position="-10 0 -10">
        <a-entity gltf-model="url(https://cdn.aframe.io/examples/ar/models/triceratops/scene.gltf)" scale="0.5 0.5 0.5"></a-entity>
      </a-entity> */}

      <a-entity position="-10 0 -10">
        <a-entity gltf-model="url(https://storage.googleapis.com/chatgpt_memory/a-demo/test/scene.gltf)" scale="0.5 0.5 0.5"></a-entity>
      </a-entity>
      

      

      {/* VR模式切换和相机设置 */}
      <a-camera>
        <a-cursor></a-cursor>
      </a-camera>
    </a-scene>
  );
};

export default VRScene;