import React from 'react';
import VRScene from './VRScene'; // 假设VRScene组件定义在VRScene.js文件中

class App extends React.Component {
  render() {
    return (
      <div>
        <h1>我的A-Frame VR示例</h1>
        <VRScene /> {/* 在这里渲染VR场景组件 */}
      </div>
    );
  }
}

export default App;